import { deferLoading, Priority, registerApp } from '@mfl/framework';
import { navigationItems } from '@mfe/core-navigation';
import { getAccountBasicInfo } from '@mfl/platform-shell';
import { resolveImagePath } from './utils';

const baseRouteSetup = '/v1/setup';
const baseRouteOnboarding = '/v1/onboarding';
const isSetup = window.location.pathname.includes(baseRouteSetup);

export async function register() {
  const accountData = getAccountBasicInfo();
  const routesArray: string[] = [];
  if (!accountData.setupCompleted) {
    routesArray.push(baseRouteSetup);
    if (isSetup) deferLoading(); // temporary solution
  }
  if (!accountData.signupFlowCompleted) {
    console.log('IN PLUGIN');
    routesArray.push(baseRouteOnboarding);
    deferLoading(); // temporary solution
  }

  await registerApp('onboarding', routesArray);

  if (!accountData.setupCompleted) {
    navigationItems.register(
      {
        key: 'ACCOUNT-SETUP',
        text: 'Setup',
        iconUrl: resolveImagePath('account-setup/account-setup.svg'),
        iconUrlActive: resolveImagePath(
          'account-setup/account-setup-active.svg'
        ),
        route: baseRouteSetup,
      },
      { priority: Priority.High }
    );
  }
}
